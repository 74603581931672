import resume from '../assets/pdf/saidur_3.pdf'
import profile from '../assets/personal/saidur1.jpg'

export const headerData = {
    name: 'Md Saidur Rahman',
    title: "Software Engineer",
    desciption:"In the realm of wires and circuits, where dreams find their home,Technology weaves a tapestry, where possibilities roam.Bits and bytes converge, unleashing innovation's might,A symphony of progress, where the future takes flight.",
    image: profile,
    resumePdf: resume
}
